<template>
  <b-modal
    size="lg"
    :id="'modal-owners'"
    title="Propietarios Info"
    ok-only
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <b-card-text>
      <b-row class="mb-2 mt-1">
        <b-col cols="12" md="6">
          <ValidationProvider name="Hoteles" rules="required">
            <b-form-group
              slot-scope="{ valid, errors }"
              label="Seleccione hotel*"
            >
              <b-form-select
                :state="errors[0] ? false : valid ? true : null"
                v-model="idHotel"
              >
                <option value="">Seleccione hotel</option>
                <option
                  v-for="hotel in hotels"
                  :key="hotel.id"
                  :value="hotel.id"
                >
                  {{ hotel.name }}
                </option>
              </b-form-select>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Numero de vivienda*">
            <b-form-input
              type="number"
              placeholder="Buscar por numero de vivienda"
              v-model="numberHouse"
              @keypress="formatPhoneNumber"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="12 text-right" md="12">
          <b-button variant="success" @click="sendHousing()">
            <b-icon icon="search"></b-icon> Buscar
          </b-button>
        </b-col>
        <b-col v-if="owners != ''"  cols="12 mt-2" md="12">
         <h6 class="text-center"> {{owners.housingcode}} | {{owners.housingname}}</h6>
          <b-table
            :items="owners.owner"
            :fields="tableColumns"
            responsive
            small
            sticky-header="300"
            primary-key="id"
            class="custom-size"
          >
            <template #cell(action)="row">
              <b-button variant="primary" class="btn-add" @click="sendInfo(row.item, owners.housingid)" :disabled="isLoadingHouses">
                <feather-icon icon="PlusIcon" size="16" />
              </b-button>
            </template>
          </b-table>
        </b-col>
        <b-col v-if="isLoadingHouses" cols="12 mt-2" md="12" class="text-center">
          <b-spinner /> Cargando viviendas
        </b-col>
        <b-alert class="mt-2" variant="danger" v-if="validHousing" show>
          <div class="alert-body text-center">
            <span><strong>¡Sin registros!</strong> No se encontraron propietarios registrados.</span>
          </div>
        </b-alert>
      </b-row>
    </b-card-text>
  </b-modal>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  async mounted() {},

  data() {
    return {
      numberHouse: "",
      idHotel: "",
      isLoadingHouses: false,
      validHousing: false,
      mqShallShowLeftSidebar: false,
      tableColumns: [
        { key:'ownerEmail', label: 'Correo'},
        { key:'ownerName', label: 'Nombre', formatter: (value, key, item) => {
          return item.ownerName + " " + item.ownerLastname
        }},
        { key:'ownerPhone', label: 'telefono'},
        { key:'action', label: 'Usar'},
      ]
    }
  },
  computed: {
    ...mapState("shop", ["benefits", "owners"]),
    ...mapState("start", ["hotels"]),
  },
  methods: {
    ...mapActions("fivesClubCatalogs", ["getHousingOwners",]),
    ...mapMutations("shop", ["setContract", "setOwners"]),

    async sendInfo(item, housingid) {
      this.$emit("reset-every-Benefit")

      this.$emit("set-owners", item,housingid);
    },

    async sendHousing() {
      this.isLoadingHouses=true
      await this.setContract("");
      const payload = {
        numberHouse: this.numberHouse,
        idHotel: this.idHotel,
      };

      const { data:ownersInfo } = await this.getHousingOwners(payload);
      await this.setOwners(ownersInfo);
      this.validHousing = !this.owners;
      this.isLoadingHouses=false
    },
    formatPhoneNumber(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      //43 es +, 48-57(numeros del 0 al 9, 32 espacio, 45 es - )
      const codesAllowed = [32, 43, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
      const respuesta = codesAllowed.includes(keyCode);
      if (!respuesta) event.preventDefault()
    },
  },
};
</script>





