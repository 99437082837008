<template>
  <div class="customer-card height">
    <b-card :title="cart.cliente.name+' '+cart.cliente.lastname" class="">
      <b-row>
        <b-col cols="6" class="margin-customer-card">
          📧 <strong>Correo:</strong><br> {{ cart.cliente.email }}
        </b-col>
        <b-col cols="6" class="margin-customer-card">
          📱<strong>Teléfono:</strong><br> {{ cart.cliente.phone }}
        </b-col>
        <b-col cols="6" class="margin-customer-card">
          🏩 <strong>Hotel:</strong><br> {{ nameHotel }}
        </b-col>
        <b-col cols="6" class="margin-customer-card">
          👮 <strong>Tipo Cliente:</strong><br> {{ tipoCliente }}
        </b-col>
        <b-col cols="6" v-if="cart.cliente.credit!=0" class="margin-customer-card">
          💵 <strong>Saldo a favor:</strong><br> $ {{ cart.cliente.credit }}
        </b-col>
        <b-col cols="6" v-if="cart.cliente.resortCredit!=0" class="margin-customer-card">
          💵 <strong>Resort Credit:</strong><br> $ {{ cart.cliente.resortCredit }}
        </b-col>
        <b-col cols="6" class="margin-customer-card" v-if="cart.cliente.customeragent">
          😊<strong>Refiere:</strong><br> {{ cart.cliente.customeragent }}
        </b-col>
      </b-row>
      <hr>
      <b-row class="mb-1">
        <b-col cols="6" class="margin-customer-card">
          💵<strong>Forma de pago:</strong><br>
        </b-col>
        <b-col cols="6" class="margin-customer-card">
          🔗<strong>Referencia:</strong><br>
        </b-col>
        <b-col class="margin-customer-card">
          <b-row v-for="(item,index ) in cart.cliente.paymentList" :key="index">
            <b-col cols="6" md="6">
              <span>
                <span v-if="item.name.toUpperCase().includes('CARD')">💳</span>
                <span v-else>💰</span>
                {{  item.name }} <br>
              </span>
            </b-col>
            <b-col cols="6">
              <span>
                <span v-if="item.name.toUpperCase().includes('CARD')">💳</span>
                <span v-else>💰</span>
                {{  item.reference }} <br>
              </span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- Totales -->
      <table class="table table-sm">
        <tbody>
          <tr>
            <td class="textSmall"><strong>Subtotal: <br></strong>${{ subTotal }}</td>
            <td class="textSmall"><strong>TC: <br></strong>  ${{ tipoCambio }}</td>
          </tr>
          <tr>
            <!-- <td class="textSmall"><strong>Crédito Disponible: </strong>${{ totalCredit}} </td>
            <td class="textSmall"><strong>Resort Crédito Disponible: </strong>${{ totalResortCredit}} </td> -->
          </tr>
          <tr>
            <td class="textSmall"><strong>Descuento: <br></strong> ${{totalDiscount}}</td>
            <td class="textSmall"><strong>Total Divisa: <br></strong> ${{ totalDivisa }} </td>
          </tr>
          <tr>
            <td v-if="subTotalParcial != 0" class="textSmall"><strong>Total Divisa Parcial: <br></strong> ${{ totalDivisaBooking }} </td>
          </tr>
          <tr v-if="subTotalParcial != 0" >
            <td class="textSmall totalParcial"><strong>Primer pago  Reserva: <br></strong> ${{ subTotalParcial }} </td>
          </tr>
          <tr v-if="subTotalParcial != 0" >
            <td class="textSmall totalParcial"><strong>Primer pago parcial: <br></strong> ${{ subTotalParcialBooking }} </td>
          </tr>
          <tr>
            <td class="textSmall"><strong>Total: <br></strong> ${{ total }} </td>
          </tr>
        </tbody>
      </table>

      <!-- Productos en el carrito que les falta completar campos -->
      <div v-if="notReadyItems.length" class="mt-2">
        <hr>
        <b>Productos en el carrito que les falta completar campos: </b><hr>
        <table class="table table-sm table-striped">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Item</th>
              <th scope="col">Campo o acción faltante</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, indice) in notReadyItems" :key="item.key">
              <td scope="row"><b>{{indice + 1}}:</b>  {{ item.name }} </td>
              <td scope="row" style="color: red;">
                <span v-for="(valor) in item.fields" :key="valor.key">
                  <li v-if="valor.isBenefit">Favor de aplicar la fecha de operacion para el beneficio</li>
                  <li v-if="!valor.campo.name">{{valor.campo}}</li>
                  <b-badge class="badgemargin" :id="`tooltip${valor.key}`" variant="danger" v-if="valor.campo.name">{{valor.campo.name}}</b-badge>
                  <b-tooltip :target="`tooltip${valor.key}`" triggers="hover">
                    <span v-for="(campos, index) in valor.campo.campos" :key="index" >
                      <li class="align-left" v-if="campos">{{campos}}</li>
                    </span>
                  </b-tooltip>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="cart.files" class="mt-2">
        <hr>
        <b-row>
          <b-col cols="" class="margin-customer-card">
            📄 <strong>Archivos:</strong><br>
            <b-row>
              <b-col cols="6" v-for="(item, index) in cart.files.filedata" :key="index">
                <div class="mb-1">
                  <span>📎</span>
                  <a :href="item.url" target="_blank">{{ item.name }}</a><br>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <strong>Notas:</strong> <br>{{cart.files.notes}}
          </b-col>
        </b-row>
      </div>

    </b-card>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { itemsCartNotReady }from '@/helpers/shopHelper'
import { utils } from '@/modules/fivesClub/mixins/utils'

export default {
  mixins: [utils],
  props: {
    cart: {
      type: Object,
      required: true,
    },
    nameHotel:{
      type: String,
      required: true,
    },
    tipoCliente:{
      type: String,
      required: true,
    },

     formaPagoList:{
      type: Array,
      required: false,
    },
    subTotal:{
      type: String,
      required: true,
    },
    tipoCambio:{
      type: String,
      required: true,
    },
    totalDivisa:{
      type: String,
      required: true,
    },
    totalDivisaBooking:{
      type: String,
      required: true,
    },
    total:{
      type: String,
      required: true,
    }
  },
  computed: {
    ...mapGetters('shop',['subTotalParcial', 'subTotalParcialBooking', 'totalDiscount']),
    notReadyItems(){
      return itemsCartNotReady( this.cart.items )
    }
  }
}
</script>

<style lang="scss" scoped>

.badgemargin{
  margin: 3px 5px 3px 0;
}
.totalParcial{
color:crimson
}
.height{
  height: fit-content !important;
}
</style>