<template>
  <b-modal id="authorize-booking" ok-only no-close-on-backdrop size="md" centered hide-footer
		title="Autorizar tarifas de bookings en la orden"
	>     
    <ValidationObserver ref="observerRateModal" v-slot="{ invalid }" >
      <b-form @submit.prevent="sendDataUserAuthorizeRate(userAuthorizeRate)">
        <div class="col-md-12">
          <b-row>
            <b-col md="12">
              <ValidationProvider rules="required" name="Tarifa">
                <b-form-group label="Usuario" slot-scope="{ valid, errors }">
                  <b-form-input
                    type="text"
                    v-model="userAuthorizeRate.username"
                    :state="errors[0] ? false : (valid ? true : null)"
                    @change="isValidUserAuthorizeRate(userAuthorizeRate)"
                  ></b-form-input>											
                  <b-form-invalid-feedback>{{ errors[0] ? 'Se debe indicar el usuario' : '' }} </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="12">
              <ValidationProvider rules="required" name="Clave de autorización">
                <b-form-group label="Clave de autorización" slot-scope="{ valid, errors }">
                  <b-form-input
                    type="password"
                    v-model="userAuthorizeRate.code"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input> 
                  <b-form-invalid-feedback>{{ errors[0] ? 'Se debe indicar la clave de autorización del usuario' : '' }} </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <!-- <b-col md="12" v-if="room.detailSelected.allowPriceMatch">
              <ValidationProvider rules="required" name="Comprobante para autorización">
                <b-form-group label="Comprobante para autorización" slot-scope="{ valid, errors }">
                  <b-form-file
                    v-model="file"
                    @input="setImgFile(file)"
                    placeholder="Adjunte una imagen como evidencia..."
                    drop-placeholder="Drop file here..."
                    :state="errors[0] ? false : (valid ? true : null)"
                    browse-text="Buscar imagen"
                />
                  <b-form-invalid-feedback>{{ errors[0] ? 'Se debe adjuntar la evidencia para la autorización' : '' }} </b-form-invalid-feedback>
                </b-form-group>                
              </ValidationProvider>
              <b-card-text class="my-1">Imagen seleccionada: <strong>{{ file ? file.name : '' }}</strong></b-card-text>
            </b-col> -->
            <b-col md="12">
              <div class="float-right">
                <b-button 
                  variant="primary" 
                  type="submit"										
                  ref="btnShowRate"
                  :disabled="invalid || userAuthorizeRate.isInvalid">
                  <span> Autorizar</span>
                </b-button> 
                <!-- {{ invalid || userAuthorizeRate.isInvalid}} -->
                <!-- invalid debe ser falso y user isInvalid deber ser falso -->
              </div>
            </b-col>
          </b-row>
        </div>
      </b-form>					
    </ValidationObserver>				
	</b-modal>	
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"

import { currentDate, showAlertMessage } from '@/helpers/helpers'

import * as moment from 'moment'

export default {  
  data(){
    return {
      file: null
    }
  },
  computed:{
    ...mapState("shop", ["userAuthorizeRate"]),   
    ...mapState("auth", ["user"]),   

  },
  methods:{     
    ...mapActions('shop',['checkUserAuthorizeRate','authorizeRate']),
    ...mapMutations('shop',['unsetDataUserAuthorization','setDataAuthBookingItem']),

    async isValidUserAuthorizeRate(user){
      const { canalventa } = this.user
      const payload = { username: user.username, canalVenta: canalventa.id}
      await this.checkUserAuthorizeRate( payload )      
    },
    setImgFile(file){
      if(file){
        this.file = file
      } else {      
        const text = `😪😪 Tiene que adjuntar un archivo de tipo imagen`
				showAlertMessage( 'No hay imagen', 'InfoIcon', text, 'danger', 4000, 'bottom-right')
      }
    },
    async sendDataUserAuthorizeRate(user){           
      const { username, idUser, code, urlAuthorization } = user
      const usuario = { user: idUser,  code, isPriceMatch: false }
      const payload = { user: usuario, username, fileImg: null }      
      await this.authorizeRate( payload )   
      if ( this.userAuthorizeRate.idUser && !this.userAuthorizeRate.isInvalid){				
        const payloadAuth = {        
          dateAuthorization:  currentDate(),
          idUserAuthorizeRate: idUser,
          urlAuthorization,
          requiresAuthorization: false
        }		
				this.closeModalAuthorizeBooking()
        this.setDataAuthBookingItem(payloadAuth)
				this.unsetDataUserAuthorization()        
			}   
    },
    closeModalAuthorizeBooking() {           
      const modal = "authorize-booking" 
      const btnShowBooking = "btnShowBooking"
      this.$root.$emit("bv::hide::modal", modal, btnShowBooking)
    },
  }
}
</script>
