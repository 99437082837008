<template>
  <div class="pay-format mb-2">
    <hr>
    <ValidationObserver ref="formatPayForm" tag="form" :disabled="true">
      <b-row style="margin: 0;">
        <!-- Selección de Método de Pago -->
        <b-col cols="12" md="6">
          <b-row>
            <b-col cols="12 " md="6">
              <ValidationProvider name="forma de pago" rules="required" v-if="user">
                <b-form-group slot-scope="{ valid, errors }" label="Método de pago*" >
                  <b-form-select
                    :state="errors[0] ? false : (valid ? true : null)"
                    v-model="paymentFormat.id"
                    @change="validPayRoom"
                  >
                    <option value="" disabled>Elija método de pago</option>
                    <option v-for="pm in paymentMethodList" :key="pm.id" :value="pm.id">
                      {{ pm.name }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col cols="12" md="6">
              <ValidationProvider rules="min:0" name="totalPay">
                <b-form-group slot-scope="{ valid, errors }" label="Total a pagar ">
                  <b-form-input
                    type="number"
                    v-model="paymentFormat.total"
                    :state="errors[0] ? false : valid ? true : null"
                    placeholder="Total a pagar"
                    :disabled='disablePay'
                  >
                  </b-form-input>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col cols="12" md="5">
              <ValidationProvider :rules="referencia" name="referencia">
                <b-form-group slot-scope="{ valid, errors }" label="Referencia">
                  <b-form-input
                    type="text"
                    v-model="paymentFormat.reference"
                    :state="errors[0] ? false : valid ? true : paymentFormat.id ? true : false"
                    placeholder="Ingrese la referencia"
                  >
                  </b-form-input>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col cols="12" md="7" style="padding-bottom:1rem">
              <div class="float-right mt-personal">
                <b-button @click="sendPayFormat()" variant="info">
                  <b-icon icon="cash"></b-icon> Agregar pago
                </b-button>
              </div>

            </b-col>
          </b-row>
        </b-col>
        <!-- Selección de Método de Pago -->

        <!-- Método de Pago -->
        <b-col cols="12" md="6">
          <div style="overflow-x: auto;">
            <b-table
              small
              bordered
              responsive
              :items="cart.cliente.paymentList"
              :fields="paymentListFields"
              thead-class="thead-dark"
            >
              <template #cell(delete)="row">
                <b-button size="sm" variant="danger" @click="deletePay(row.item.id)" v-if="!hasAnOwnerBooking(row.item.id)">
                  <b-icon icon="trash-fill"></b-icon>
                </b-button>
              </template>
            </b-table>
          </div>
        </b-col>
        <!-- Método de Pago -->

        <!-- Terminal Bancaria -->
        <b-col cols="" v-if="paymentFormat.id == 7">
          <b-row>
            <b-col cols="12" md="12">
              <hr>
              <b class="textPago">Agregar datos de terminal bancaria:</b><br><br>
            </b-col>
            <b-col cols="12" md="6">
              <ValidationProvider rules="required" name="Tarjeta">
                <b-form-group slot-scope="{ valid, errors }" label="Número de Tarjeta ">
                  <b-form-input
                    type="text"
                    v-model="paymentFormat.cc_bank"
                    maxlength="4"
                    :state="errors[0] ? false : valid ? true : null"
                    placeholder="XXXX"
                    :disabled="hasCreditCardTerminalInfo"
                    @keypress="formatCardNumber"
                  >
                  </b-form-input>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col cols="12" md="6">
              <ValidationProvider rules="required" name="Aprobación">
                <b-form-group slot-scope="{ valid, errors }" label="Número de Aprobación">
                  <b-form-input
                    type="text"
                    maxlength="10"
                    v-model="paymentFormat.auth_bank"
                    :state="errors[0] ? false : valid ? true : null"
                    placeholder="Referencia"
                    :disabled="hasCreditCardTerminalInfo"
                  >
                  </b-form-input>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col cols="12 " md="6">
              <ValidationProvider name="Tipo de Tarjeta" rules="required">
                <b-form-group
                  slot-scope="{ valid, errors }"
                  label="Tipo de Tarjeta"
                  :disabled="hasCreditCardTerminalInfo"
                >
                  <b-form-select
                  :state="errors[0] ? false : (valid ? true : null)"
                    v-model="paymentFormat.cc_type_bank"
                  >
                    <option disabled value="">Seleccione Tipo de Tarjeta</option>
                    <option
                    v-for="(emisor, index) in cardsEmit"
                    :key="index"
                    :value="emisor.value"
                    >
                    {{ emisor.text }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </b-col>
        <!-- Terminal Bancaria -->

        <!-- Selección de beneficios -->
        <b-col cols="12" md="6" v-if="paymentFormat.id == 1055 && hasOwnerBookingBothBenefits">
          <div class="" cols="">
            <hr>
            <b>Beneficios disponibles para usar en la reservación</b><br>
            <label class="textPago mt-1">Seleccione el beneficio a usar: <br></label>
            <b-row class="d-flex justify-content-center align-center">
              <b-col
                v-for="benefits in benefitsLists"
                :key="benefits.id"
                cols=""
                :class="`benefit-space p-1 border rounded ${benefits.selected ? 'benefit-selected-box' : 'benefit-box'}`"
                @click="setSelectedBenefit(benefits)"
              >
                <b-row class="text-left custom-width">
                  <b-col class="text-center" cols="1"><feather-icon :icon="benefits.selected ? 'CheckCircleIcon' : 'CircleIcon'" size="18" /></b-col>
                  <b-col class=""><label><strong>{{benefits.name}} </strong></label><br></b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col cols="12" md="12">
          <!-- <div class="textPago">Agregar solo dos formas de pago</div> -->
        </b-col>
      </b-row>
    </ValidationObserver>
  </div>
</template>

<script>
//eslint-disable-line
import { mapState, mapGetters, mapMutations } from "vuex";
import { cardsEmit } from '@/data/data'
import { showAlertMessage } from '@/helpers/helpers'

export default {
  props: {
    disablePay: {
      type: Boolean,
      required: true
    },
    paymentMethods: {
      type: Array,
      required: true
    }
  },
  created(){
    if (this.selectedOwnerBookingBenefit) {
      this.selectedBenefit = structuredClone(this.selectedOwnerBookingBenefit)
      this.benefitsLists.find(benefit => benefit.id == this.selectedBenefit.id).selected = this.selectedBenefit.selected
    }
  },
  watch: {
    paymentFormat: { // Detecto cualquier cambio en los métodos de pago para ejecutar esta función
      deep: true,
      async handler() {
        this.reasignPaymentList()
      },
    },
  },
  data() {
    return {
      cardsEmit,
      isReloadingPaymentMethods: false,
      paymentListFields: [
        {key: 'name', label: 'Forma de pago'},
        {key: 'reference', label: 'Referencia'},
        {key: 'total', label: 'Total'},
        {key: 'delete', label: 'Borrar'}
      ],
      benefitsLists: [
        {id: 'NTC', name: 'Noches por Contrato', selected: false},
        {id: 'NTX', name: 'Noches Extra', selected: false}
      ],
      selectedBenefit: null
    }
  },
  computed: {
    ...mapState('auth',['user']),
    ...mapState("shop", ["cart", "errors", "paymentFormat" ,"listBenefitUsed"]),
    ...mapGetters('shop',['selectedOwnerBookingBenefit']),
    paymentMethodSelected(){
      cart.cliente.paymentList
    },
    paymentMethodList(){
      if (this.cart.cliente.customertype != 2) return this.paymentMethods.filter(pm => pm.isownersPayment == false)
      return this.paymentMethods
    },
    referencia(){
      const paymentmethod = this.user.fp.find(payment=> payment.id === this.paymentFormat?.id)
      if (paymentmethod && paymentmethod.requireReference) return 'required'
      return ''
    },
    hasOwnerBookingBothBenefits(){
      return (this.cart.ownerbookingbenefits?.active && this.cart.ownerbookingbenefits?.hasBothNights) || false
    },
    hasCreditCardTerminalInfo(){
      return this.cart.cliente.paymentList.some(pm => pm.id == 7)
    },
    hasOwnerBenefitInfo(){
      return this.cart.cliente.paymentList.some(pm => pm.id == 1055)
    }
  },
  methods: {
    ...mapMutations("shop", ["setBenefitSelectedInOwnerBooking", "reasignPaymentList"]),
    deletePay(id) {
      this.$emit("delete-pay", id)
      if (id == 7) this.deleteTerminal()
    },
    deleteTerminal() {
      this.$emit("delete-terminal")
    },
    setReference(reference){
      this.$emit("setReference-set", reference)
    },
    setFormatId(formaPagoId){
      this.$emit("setFormatId-set", formaPagoId)
    },
    async sendPayFormat(){
      const paymentmethod = this.user.fp.find(payment=> payment.id === this.paymentFormat?.id)
      const hasAnOwnerBooking = this.cart.items.some(items => items.categoryName == "ReservasOwners")

      if (paymentmethod?.isownersPayment && !hasAnOwnerBooking) {
        if (this.cart.benefits.filter(benefit => benefit.benfitcode != "RCOH").length == 0) return showAlertMessage( 'Aviso', 'InfoIcon', 'No cuenta con beneficios suficientes para aplicar esta forma de pago', 'warning', 4000, 'bottom-right')
        // if (this.listBenefitUsed.length == 0) return showAlertMessage( 'Aviso', 'InfoIcon', 'Esta forma de pago requiere aplicar por lo menos un beneficio de manera obligatoria', 'warning', 4000, 'bottom-right')
      } // else if (hasAnOwnerBooking && !this.selectedBenefit) return showAlertMessage( 'Aviso', 'InfoIcon', 'Seleccione un beneficio para continuar', 'warning', 4000, 'bottom-right')

      const valid = await this.$refs.formatPayForm.validate()

      if (valid) {
        this.isEpayment()
        this.$emit("sendPayFormat-set")
      }
    },
    setTotalFormaPago(totalPago){
      this.$emit("setTotalPago-set", totalPago)
    },
    hasAnOwnerBooking(id){
      return this.cart.items.some(items => items.categoryName == "ReservasOwners" ) && id == 1055
    },
    validPayRoom(e){
      if(!e) return false
      if (!this.cart.cliente.paymentList.some(pm => pm.id == 7)) this.deleteTerminal()
      this.isEpayment()
      if (this.referencia) showAlertMessage( 'Aviso', 'InfoIcon', 'Esta forma de pago requiere una referencia de manera obligatoria', 'warning', 4000, 'bottom-right')
      this.$emit("validPayRoom-set", e)
    },
    isEpayment(){
      this.$emit("set-epayment")
    },
    formatCardNumber(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which
      //43 es +, 48-57(numeros del 0 al 9, 32 espacio, 45 es - )
      const codesAllowed = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
      const respuesta = codesAllowed.includes(keyCode)
      if (!respuesta) {
        event.preventDefault()
      }
    },
    setSelectedBenefit(benefit) {
      benefit.selected = !benefit.selected
      this.selectedBenefit = benefit?.id == this.selectedBenefit?.id ? null : benefit
      this.benefitsLists.forEach(benefits => {
        if (benefits.id != benefit.id) benefits.selected = false
      })
      this.setBenefitSelectedInOwnerBooking(this.selectedBenefit)
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "~vue-form-wizard/dist/vue-form-wizard.min.css";
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
.totalParcial {
  color: crimson;
}

span.textPago {
  margin: 0 0 8px 0;
  display: block;
}
button.btn.btn-info {
  background: #0095b7!important;
}
.float-right.mt-personal {
  margin: 25px 0 0 0px;
}

.benefit-space{
  margin: 0.3rem 0.8rem !important;
}
.benefit-selected-box{
  border: 1px solid #cdeddb !important;
  transition: all 0.5s ease;
}
.benefit-selected-box:hover{
  border: 1px solid #28c76f !important;
  transition: all 0.5s ease;
}
.benefit-box{
  border: 1px solid #dff4f5 !important;
  transition: all 0.5s ease;
}
.benefit-box:hover{
  border: 1px solid #00cfe8 !important;
  transition: all 0.5s ease;
}
</style>
