<template>
  <div>
    <b-row>
      <b-col>
        <label>Añadir nuevo archivo</label>
        <b-form-file
          v-model="file"
          multiple
          placeholder="Escoge un archivo o sueltalo aquí..."
          drop-placeholder="Suelta el archivo aquí..."
          browse-text="Buscar archivo"
        />
        <label class="float-right">Máx. 5 archivos de 2Mb c/u</label>
        <span v-if="cart.files">Archivo actual: {{cart.files.filename}} <br></span>
      </b-col>
      <b-col>
        <validation-provider name="notas" rules="required">
          <b-form-group slot-scope="{ valid, errors }" label="Añadir notas al archivo" >
            <b-form-textarea
              v-model="notes"
              :state="errors[0] ? false : valid ? true : null"
            />
            <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row align-h="end" class="mt-2">
      <b-col md="2">
          <b-button
            type="button"
            class="btn-block"
            variant="warning"
            @click="resetFile()"
            :disabled="!file"
          >
            Reset
          </b-button>
      </b-col>
      <b-col md="2">
          <b-button
            type="submit"
            class="btn-block"
            variant="primary"
            @click="SaveFile()"
            :disabled="!file || !notes"
          >
            <b-spinner small v-if="isSavingFiles" /> Guardar
          </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex"
import {  showAlertMessage, filterFilesByType, filterFilesBySize, filterFilesByName, getLocalFilePath } from '@/helpers/helpers'

export default {
  props:{
    orderDetail:{
      type: Array,
      required: false
    },
  },
  data(){
    return {
      isSavingFiles: false,
      file: null,
      notes: '',
    }
  },
  computed:{
    ...mapState('auth',['user']),
    ...mapState("shop", ["cart",]),
  },
  methods: {
    async SaveFile(){
      if (!this.file || this.file.length == 0) return showAlertMessage( 'Sin archivos', 'InfoIcon', 'Por favor, elija un archivo', 'warning', 4000, 'bottom-right')

      if (this.file.length > 5) {
        this.file = null
        return showAlertMessage( 'Maximo alcanzado', 'InfoIcon', 'No puedes subir más de 5 archivos', 'warning', 4000, 'bottom-right')
      }

      const filteredByType = filterFilesByType(this.file)
      if (!filteredByType) return

      const filteredBySize = filterFilesBySize(filteredByType, 2202009.6)
      if (!filteredBySize) return

      const filteredByName = filterFilesByName(filteredBySize)
      if (!filteredByName) return

      // const names = this.file.map(file => file.name)
      const types = this.file.map(file => {
        return {
          name: file.name,
          size: file.size,
          type: file.type,
          url: getLocalFilePath(file)
        }
      })

      const payload = {
        file: this.file,
        filetype: this.file.type,
        filedata: types,
        uploadUser: this.user.idUser,
        notes: this.notes,
      }
      this.$emit("set-files", payload)
    },
    resetFile(){
      this.file = null
    }
  }
}
</script>