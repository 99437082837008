<template>
  <b-modal
    id="modal-room-charge" :title="'Cargo habitación: '+nameHotel" size="lg"
    no-close-on-backdrop hide-footer hide-header-close
  >
    <b-card-text>
      <b-container>
        <ValidationObserver ref="observer" tag="form" v-slot="{ invalid }">
          <b-form @submit.prevent="searchDataRoom">
            <b-row>
              <b-col>
                <ValidationProvider rules="required" name="apellido">
                  <b-input-group prepend="Apellido" slot-scope="{ valid, errors }" label="apellido">
                    <b-form-input
                      v-model="cliente.lastnameSearch"
                      :state="errors[0] ? false : (valid ? true : null)"
                      placeholder="Ingrese apellido para buscar huesped"
                      @change="setLastnameCharge(cliente.lastnameSearch)">
                    </b-form-input>
                    <b-input-group-append>
                      <b-button type="submit" :disabled="invalid" :variant=" invalid ? 'danger': 'success'" >Buscar</b-button>
                    </b-input-group-append>
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-input-group>
                </ValidationProvider>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
        <b-form @submit.prevent="applyPayment">
          <b-row class="mt-3">
            <b-col>
              <b-table
                v-if="guests.length"
                :items="guests"
                :fields="tableColumns"
                responsive
                small
                :sort-desc.sync="isSortDirDesc"
                primary-key="id"
                class="table table-sm table-striped"
                thead-class="thead-dark"
              >
                <template #cell(mainGuest)="data">
                  <b-row align-v="center" class="width-custom">
                    <b-col md="1" v-if="data.item.iconnopost==0 && (data.item.creditlimit - data.item.balance)>=total && total>0" class="d-inline">
                      <b-form-checkbox
                        v-if="checkoutIsValid(data.item.dateto)"
                        v-model="data.item.selected"
                        :disabled="isAlreadySelected && !data.item.selected"
                        :state="data.item.selected ? true : null"
                        @change="setNumeroDeReserva(data.item, data.index)"
                      />
                    </b-col>
                    <b-col>
                      <b-button
                        :class="`${checkoutIsValid(data.item.dateto) ? 'noDecoration' : 'noDecoration text-red' } p-0 m-0`"
                        @click="checkIfValidDate(data.item.dateto)"
                        variant="link"
                        size="sm"
                        type="button"
                      > {{data.item.guestname}}
                      </b-button>
                    </b-col>
                  </b-row>
                </template>
                <template #cell(guests)="data">
                  <div v-if="data.item.extras && data.item.extras.length">
                    <b-button
                      :id="'popover-guest'+data.index" variant="info" class="btn-sm"
                    > <b-icon-info-circle-fill></b-icon-info-circle-fill>
                    </b-button>
                    <b-popover :target="'popover-guest'+data.index" variant="info" triggers="focus">
                      <template #title>Huespedes en la habitación</template>
                      <b>Principal :</b> {{data.item.guestname}}<br>
                      <div v-for="(extra, i) in data.item.extras" :key="i">
                        <b>{{i + 1 }} :</b> {{extra.guestname}} <br>
                      </div>
                    </b-popover>
                  </div>
                </template>

                <template #cell(creditlimit)="row">
                  <div>
                    <span class="font-weight-bold">Límite de crédito:</span> {{ row.item.creditlimit }} <br>
                    <!-- <span class="font-weight-bold">Disponible:</span> {{(row.item.creditlimit - row.item.balance)}} <br> -->
                    <span class="font-weight-bold">Usado:</span> {{ row.item.balance }}
                  </div>
                </template>

                <template #cell(notes)="row">
                  <div v-if="row.item.iconnopost!=0 && total > 0">
                    No tiene permitido cargos habitación <br>
                  </div>
                  <div v-if="(row.item.creditlimit - row.item.balance)<=total && total>0">
                    Importe a cargar es mayor al credito restante. Credito: {{(row.item.creditlimit - row.item.balance)}} <br>
                  </div>
                  <div v-if="!checkoutIsValid(row.item.dateto)">
                    No puede hacer cargos habitación, el Check Out es hoy <br>
                  </div>
                </template>
              </b-table>

              <b-alert show variant="danger" v-if="guests.length == 0 && !isLoadingProcess">
                <div class="alert-body text-center"> 😣 <span>No hay información de huéspedes</span></div>
              </b-alert>
              <div class="text-center" v-if="isLoadingProcess">
                <b-spinner label="Loading..." variant="success"/>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col class="col-md-8">
              <table class="table table-sm">
                <tbody>
                  <tr>
                    <td class="textSmall"> <strong>Subtotal: </strong>${{ subTotal }}</td>
                    <td class="textSmall"><strong>TC: </strong>  ${{ tipoCambio }}</td>
                  </tr>
                  <tr>
                    <td class="textSmall"><strong>Descuento: </strong> ${{totalDescuento}}</td>
                    <td class="textSmall"><strong>Total Divisa: </strong> ${{ totalDivisa }} </td>
                  </tr>
                  <tr>
                    <td class="textSmall"> <strong>Total: </strong> ${{ total }} </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col class="col-md-4">
              <div class="float-right">
                <b-form-checkbox v-if="cart.makeRoomCharge"
                  class="mb-5"
                  v-model="cart.cliente.applyManualRoomCharge"
                  name="checkbox-1"
                  value="true"
                  unchecked-value="false"
                  @change="checkCargo($event)"
                  disabled
                >
                  Cargo Manual
                </b-form-checkbox>
                <b-button type="submit" :variant="disabledBtnCharge ? 'danger': 'success'" :disabled="disabledBtnCharge">
                  <i class="fas fa-save"></i> Cobrar
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
        <b-row class="mt-2" v-if="cliente.applyManualRoomCharge">
          <b-col class="text-justify">
            <b-alert show variant="warning">
              <div class="alert-body ">Hay por lo menos un producto sin código sihot, por lo cual no se puede realizar el cargo automatico, favor de realizar el cargo manual directamente en sihot con los siguientes productos: <br>
                <div v-if="productoSinSihotCode.length">
                  <ul>
                    <li v-for="(p, index) in productoSinSihotCode" :key="index">
                      {{p.nombre}}
                    </li>
                  </ul>
                </div>
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <button class="btn btn-danger mb-2" id="show-modal" @click.prevent="closeModalRoomCharge">
              Cerrar
            </button>
          </b-col>
        </b-row>
      </b-container>
    </b-card-text>
  </b-modal>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

import { checkItemsCartSihotCode, currentDate } from '@/helpers/helpers'

export default {
  destroyed(){
    this.eraseModalData()
  },
  data(){
    return {
      selected: null,
      disabledBtnCharge: true,
      isAlreadySelected: null,
      isSortDirDesc: true,
      tableColumns: [
        { key:'mainGuest', class:'text-center', tdClass: 'p-1', label: 'Nombre'},
        { key:'roomnumber', class:'text-center', tdClass: 'p-1', label: 'Habitación'},
        { key:'resno', class:'text-center', tdClass: 'p-1', label: 'Reserva'},
        { key:'dateto', class:'text-center text-nowrap', tdClass: 'p-1', label: 'Check Out'},
        { key:'creditlimit', class:'text-center text-nowrap', tdClass: 'p-1', label: 'Crédito'},
        { key:'guests', class:'text-center', tdClass: 'p-1', label: 'Huespedes'},
        { key:'notes', class:'text-center', tdClass: 'p-1', label: 'Nota'}
      ],
    }
  },
  props: {
    cliente : {
      type: Object,
      required: true
    },
    nameHotel: {
      type: String,
      required: true,
    },
    subTotal:{
      type: String,
      required: true
    },
    total:{
      type: String,
      required: true
    },
    totalDescuento:{
      type: String,
      required: true
    },
    totalDivisa:{
      type: String,
      required: true
    },
    tipoCambio:{
      type: String,
      required: true
    }
  },
  computed:{
    ...mapState('auth',['user']),
    ...mapState('shop',['guests', 'cart','isLoadingProcess']),
    productoSinSihotCode(){
      const respuesta = checkItemsCartSihotCode( this.cart.items ) //sihotcode en respuesta
      const { ok } = respuesta
      let items = []
      if( ok ){
          const { objetos } = respuesta
          objetos.forEach(( prod ) => {
              const producto = { nombre: prod.name || '' +' - '+prod.detaildisplay }
              items.push( producto)
          })
      }
      return items
    },

  },
  methods:{
    ...mapActions('shop',['fetchDataRoom','sendInfoClient']),
    ...mapMutations('shop',['setLastNameChargeClient','unsetDataRoom', 'unselectDataRoom','setManualRoomChargeCliente','setReservaRoomCharge','unsetLastNameSearch','unsetMakeRoomCharge']),
    setLastnameCharge( lastname ){
      this.unsetDataRoom()
      this.disabledBtnCharge = true
      this.setLastNameChargeClient( lastname )
    },
    async searchDataRoom(){
        const apellido = this.cliente.lastnameSearch
        const codigoHotel = this.cliente.hotelsihotcode
        const payload = {hotel: codigoHotel, lastname: apellido }
        await this.fetchDataRoom( payload )
        // this.$refs.observer.reset()
    },
    closeModalRoomCharge(){
      this.eraseModalData()
      this.$root.$emit('bv::hide::modal', 'modal-room-charge')
    },
    setNumeroDeReserva( guest, index){
      const { resno, roomnumber, selected,accountid } = guest
      this.disableSwitch()
      const payload = {numReserva: resno, room: roomnumber,accountid:accountid}
      if( selected ){
        this.disabledBtnCharge = false
        this.setReservaRoomCharge(payload)
      } else {
        this.unselectDataRoom()
        this.unsetMakeRoomCharge()
        this.disabledBtnCharge = true
      }
    },
    async applyPayment(){
      await this.sendInfoClient( this.cliente )
      if( this.cart.items ){
        this.$emit('reset-customer-form')
        this.closeModalRoomCharge()
      }
    },
    checkoutIsValid(date){
      const hoy = currentDate().split('-')
      const checkout = date.split('-')
      if (this.user?.canalventa?.canChargeRoomInCheckout) return true
      else if (checkout[0] == hoy[0] && checkout[1] == hoy[1] && checkout[2] == hoy[2]) return false
      else return true
    },
    checkIfValidDate(date){
      const valid = this.checkoutIsValid(date)
      if (!valid) showAlertMessage(
        "No se pudo hacer cargo a habitación", "BellIcon",
        `No se puede realizar el cargo a esta habitación debido a que hoy tiene su checkout`,
        "warning", 5000, "bottom-right"
      )
      return valid
    },
    disableSwitch(){
      const valid = this.guests.find(guest => guest.selected === true)
      this.isAlreadySelected = valid ? true : false
    },
    eraseModalData(){
      this.$emit('reset-room-charge-data')
      this.disabledBtnCharge = true
    }
  }
}
</script>

<style>
  .noDecoration {
    text-decoration: none;
  }
  .estiloRadioChecked{
    background-color: #bbdefb ;
    border-radius: .3em;
  }
  .textSmall{
    font-size: .95em;
  }
  .text-red{
    color: red;
  }
  .width-custom{
    width: 10rem;
    max-width: 15rem;
  }
</style>