<template>
  <b-modal
    id="modal-quote"
    title="Cotizador"
    size="lg"
    no-close-on-backdrop
    hide-footer
    hide-header-close
    scrollable
    centered
  >
    <ValidationObserver
      ref="quoteGeneratorForm"
      tag="form"
      :disabled="true"
    >
      <b-form @submit.prevent="sendDataClienteQuotes">
        <b-row >
          <b-col md="6">
            <ValidationProvider name="Texto de bienvenida" rules="required">
              <b-form-group label="Texto de bienvenida" slot-scope="{ valid, errors }">
                <b-form-select :state="errors[0] ? false : (valid ? true : null)"   @change="listDescrit()"
                  v-model="quoted.idWelcom"
                >
                  <option value="">Seleccione una opción</option>
                  <option v-for="dataList in dataListDescriQuoted" :key="dataList.id" :value="dataList.id">{{dataList.title}}</option>
                </b-form-select>
                <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6">
            <div v-html="quoted.dataListcontent" class="mr-1 textOverflow"/>
          </b-col>

          <b-col md="6">
            <ValidationProvider name="Texto de despedida" rules="required">
              <b-form-group label="Texto de despedida" slot-scope="{ valid, errors }">
                <b-form-select :state="errors[0] ? false : (valid ? true : null)"
                  @change="listDescritFired()"
                  v-model="quoted.idFired"
                >
                  <option value="">Seleccione una opción</option>
                  <option v-for="dataListFired in dataListDescriQuotedFired" :key="dataListFired.id" :value="dataListFired.id">
                    {{dataListFired.title}}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6">
            <div :inner-html.prop="quoted.dataListcontentFired" class="mr-1 textOverflow"/>
          </b-col>

          <b-col v-if="this.quoted.isRoom==true" md="6">
            <ValidationProvider name="Texto Amenidades Booking" rules="required">
              <b-form-group label="Texto Amenites" slot-scope="{ valid, errors }">
                <b-form-select :state="errors[0] ? false : (valid ? true : null)"
                  @change="listDescritAmenities()"
                  v-model="quoted.idAmenities"
                >
                  <option value="">Seleccione una opción</option>
                  <option v-for="dataListAmenities in dataListDescriAmenities" :key="dataListAmenities.id" :value="dataListAmenities.id">
                    {{dataListAmenities.name}}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6" v-if="this.quoted.isRoom==true" >
            <div v-html="quoted.dataListcontentAmenities" class="mr-1 textOverflow"/>
          </b-col>

          <b-col md="6">
            <ValidationProvider name="Texto de Terminos y condiciones" rules="required">
              <b-form-group label="Texto de Terminos y condiciones" slot-scope="{ valid, errors }">
                <b-form-select :state="errors[0] ? false : (valid ? true : null)"
                  @change="listDescritTerm()"
                  v-model="quoted.termsId"
                >
                  <option value="">Seleccione una opción</option>
                  <option v-for="dataListTerms in dataListDescriTerms" :key="dataListTerms.id" :value="dataListTerms.id">
                    {{dataListTerms.title}}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6" >
            <div v-html="quoted.dataListcontentTerms" class="mr-1 textOverflow"/>
          </b-col>

        </b-row>

        <b-row class="mt-2">
          <b-col>
            <button type="button" class="btn btn-danger mb-2" :disabled="isLoadingProcess" @click="closeModal()">
              Cerrar
            </button>
          </b-col>

          <b-col>
            <div class="float-right">
              <b-button variant="primary" type="submit" :disabled="isLoadingProcess">
                <svg v-if="!isLoadingProcess" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-text" viewBox="0 0 16 16">
                  <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                  <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/>
                </svg>
                <b-spinner small v-if="isLoadingProcess"/> {{atributosBtnPay.textBtn}}
              </b-button>
            </div>
          </b-col>

        </b-row>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
//eslint-disable-line
import { mapState, mapActions,mapMutations, mapGetters } from 'vuex'
import { validateDataInCart, checkItemsCartSihotCode} from '@/helpers/helpers'

export default {
  async created() {
    const customerTypes = await this.fetchTypeClients()
    this.setCustomerTypes(customerTypes) //muto state customerTypes en start
  },
  computed:{
    ...mapState('auth',['user']),
    ...mapState('shop',['cart','errors','orderCreated','isLoadingProcess','dataListDescriQuoted','dataListDescriQuotedFired','quoted','dataListDescriTerms','dataListDescriAmenities']),
    ...mapState('start',['customerTypes','hotels','languages']),
    atributosBtnPay(){
      const textBtn = this.isLoadingProcess ? 'Procesando Cotizacion' : 'Enviar Cotizacion'
      const disabledBtn = this.isLoadingProcess ? true : false

      return {textBtn, disabledBtn}
    }
  },
  methods: {
    ...mapActions('start',['fetchTypeClients']),
    ...mapActions('shop',['checkIfClientExists','sendInfoClient','fetchListDescriQuoted','fetchListDescriQuotedFired','fetchsetListDescriTerms','fetchsetListDescriAmenities']),
    ...mapMutations('auth',['setUser']),
    ...mapMutations('shop',['setPaymentMethodClient']),
    ...mapMutations('start',['setCustomerTypes']),

    closeModal(){
      this.$root.$emit('bv::hide::modal', 'modal-quote')
    },
    async sendDataClienteQuotes(){
			const isValid = await this.$refs.quoteGeneratorForm.validate()
      if (isValid) {
        //valida que ningún item tenga campos obligatorios sin llenar
        const existsItem = validateDataInCart( this.cart.items )
        /*verifica si los items tienen sihotcode, si alguno no tiene,
        habilita booleano de cargo manual al tener room charge como método de pago*/
        checkItemsCartSihotCode( this.cart.items)
        if( !existsItem ){
          //si en forma de pago interfazsihot es true, se aplica room charge
          this.quoted.isQuotes=true
          this.setPaymentMethodClient(28)
          await this.sendInfoClient( this.cart.cliente )
          this.closeModal()
          this.$emit('reset-customer-form')
        }
      }
    },
    async listDescriLoad(){
      this.dataListFired=""
      this.listDescriQuoted(1)
      this.listDescriQuotedFired(2)
    },
    async listDescriQuoted(id){
      const payload = {
        idLanguage:this.cart.cliente.language,
        typeTitle:id
      }
      await this.fetchListDescriQuoted(payload)
    },
    async listDescriQuotedFired(id){
      const payload = {
        idLanguage:this.cart.cliente.language,
        typeTitle:id
      }
      await this.fetchListDescriQuotedFired(payload)
    },
    async listDescrit(){
      if (this.quoted.idWelcom != "") this.quoted.dataListcontent=this.dataListDescriQuoted[0].content
      else this.quoted.dataListcontent=""
    },
    async listDescritFired(){
      if (this.quoted.idFired!="") this.quoted.dataListcontentFired= this.dataListDescriQuotedFired[0].content
      else this.quoted.dataListcontentFired=""
    },
    async listDescritTerm(){
      if (this.quoted.termsId!="") this.quoted.dataListcontentTerms= this.dataListDescriTerms[0].content
      else this.quoted.dataListcontentTerms=""
    },
    async listDescritAmenities(){
      if(this.quoted.idAmenities!="") this.quoted.dataListcontentAmenities= this.dataListDescriAmenities[0].content
      else this.quoted.dataListcontentAmenities=""
    },
  },
}
</script>

<style>
.noDecoration {
  text-decoration: none;
}
.estiloRadioChecked{
  background-color: #bbdefb ;
  border-radius: .3em;
}
.textSmall{
  font-size: .95em;
}


.textOverflow {
  /* white-space: nowrap; */
  overflow: hidden;
  max-height: 120px;
  word-break: break-word;
  /* padding: 0px 0 6px 0; */
  margin: 0 0 28px 0;
  font-size: 12px;
  text-align: justify;
}
</style>