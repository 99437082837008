<template>
  <b-card :title="titleFivesClub" v-if="listMemberBenefitUsed.length || listBenefitUsed.length || contractBenefitUsed" class="height">
    <div class="mt-2" v-if="contractBenefitUsed">
      <hr>
      <h6 class="pb-0">
        🏠 <strong>Vivienda:</strong><br> {{ contractBenefitUsed }}
      </h6>
    </div>

    <!-- Lista de beneficios aplicados de membresía: -->
    <div v-if="membershipDiscounts.length && membershipDiscounts.length > 0" class="mt-1">
      <hr>
      <b>Lista de beneficios de membresía aplicados:</b>
      <table class="table table-sm table-striped mt-1" v-if="membershipDiscounts.length && membershipDiscounts.length > 0">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Descuentos</th>
            <th v-if="can('marketplace_sub_menu_show_marketplace_checkout_membership_action_button_delete')" scope="col">acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, indice) in membershipDiscounts" :key="item.key">
            <td scope="row"><b>{{indice + 1}}:</b> <span class="font-weight-bold">{{ item.name }}: </span><br> DESCUENTO DEL {{item.discountApplied}} % </td>
            <td scope="row"  v-if="can('marketplace_sub_menu_show_marketplace_checkout_membership_action_button_delete')" >
              <b-button variant="danger" class="btn-borrar float-right" @click="deleteDescountMember(item)">
                <feather-icon icon="TrashIcon" size="16" />
                <div v-if="loadvalid" class="text-center collapse">
                  <b-spinner label="Loading..." variant="success" />
                </div>
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Lista de beneficios aplicados -->
    <div v-if="hasAnOwnerBookingWithBenefits || (listBenefitUsed.length && listBenefitUsed.length > 0)"  class="mt-2">
      <hr>
      <b>Lista de beneficios aplicados:</b><br>
      <span v-if="cart.ownerbookingbenefits.active">
        <label class="textPago mt-1" v-if="hasAnOwnerBookingWithBenefits">
          Beneficio por usar en reservación: <strong>{{ choosedBenefit }}</strong> <br>
        </label>
        <div class="mt-2" cols="" v-else>
          <b>No cuenta con beneficios disponibles para usar en la reservación</b><br>
          <p class="textPago mt-1">Es posible que deba solicitar autorización más tarde para activar la reserva<br></p>
        </div>
      </span>
      <table class="table table-sm table-striped mt-1" v-if="listBenefitUsed.length && listBenefitUsed.length > 0">
        <thead class="thead-dark">
          <tr>
            <th scope="col">beneficios</th>
            <th scope="col">acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, indice) in listBenefitUsed" :key="item.key">
            <td scope="row"><b>{{indice + 1}}:</b> {{ item.benfitname }} ({{ item.year }})</td>
            <td scope="row" class="float-right">
              <b-button  variant="danger" class="btn-borrar" @click="deleteDescount(item)">
                <feather-icon icon="TrashIcon" size="16" />
                <div v-if="loadvalid" class="text-center collapse">
                  <b-spinner label="Loading..." variant="success" />
                </div>
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <pre v-for="(item, idx) in listBenefitUsed" :key="idx">
        {{item}}
        <span>
          {{ idx }}: {{ item.name }}
          show: {{ item.show }}
          keyRate: {{ item.keyRate }}
        </span>
      </pre> -->
      <!-- <pre v-for="(item, idx) in membershipDiscounts" :key="idx">{{ idx }}: {{ item }}</pre> -->
    </div>

  </b-card>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import {showAlertMessage} from "@/helpers/helpers"
import { acl } from "@/modules/auth/mixins/acl"
import { utils } from '@/modules/fivesClub/mixins/utils'
import { market } from '@/modules/shop/mixins/market'

export default {
  mixins: [market, utils, acl],
  props: {
    listBenefitUsed: {
      type: Array,
      required: true,
    },

    listMemberBenefitUsed: {
      type: Array,
      required: true,
    }
  },
  data(){
    return {
      loadvalid: false,
      selectedBenefit: null,
      showMembershipDiscounts: false,
      tableColumns: [
        { key:'benefitname', label: 'Nombre', formatter: (value, key, item) => {return item.benefit[key]}},
        { key:'balance', label: 'Disponible', formatter: (value, key, item) => {return item.benefit[key]}},
        { key:'used', label: 'Usado', formatter: (value, key, item) => {return item.benefit[key]}},
      ]
    }
  },
  computed: {
    ...mapState("shop", ["cart", "paymentFormat"]),
    ...mapGetters('shop',['totalBalance', 'selectedOwnerBookingBenefit']),
    membershipDiscounts(){
      const discounts = this.listMemberBenefitUsed.filter(discount => discount.modelType !== 'rates' || (discount.modelType === 'rates' && !!discount.show))
      return discounts
    },
    hasAnOwnerBookingWithBenefits(){
      return this.cart.ownerbookingbenefits.nightsContract || this.cart.ownerbookingbenefits.nightsXtra || this.cart.ownerbookingbenefits.hasBothNights
    },
    selectedBenefitName(){
      return this.selectedOwnerBookingBenefit?.name
    },
    contractBenefitUsed(){
      const contracts = this.cart?.contract
      if (contracts?.length) {
        const contract = contracts.find((contr) => contr.id === this.cart?.contractId)
        return contract ? `${contract?.housingname || ''} | ${contract?.membershipcode || ''}` : null
      } else null
    },
    titleFivesClub(){
      if (this.listMemberBenefitUsed.length || this.listBenefitUsed.length) return 'Resumen de Beneficios'
      else if (this.contractBenefitUsed) return 'The Fives Club'
    },
    choosedBenefit(){
      const {nightsContract, nightsXtra, hasBothNights} = this.cart.ownerbookingbenefits
      if (hasBothNights) return this.selectedBenefitName || 'Por seleccionar'
      if (nightsContract) return 'Noches por Contrato'
      if (nightsXtra) return 'Noches Extra'
    },
    hasAvaiableBenefits(){
      const benefits = this.cart.benefits.filter(bn => bn.benfitcode != "RCOH" )
      return benefits.length > 0
    },
    hasAvaiableMembershipDiscounts(){
      return this.cart.membershipsDiscount.length > 0
    }
  },
  methods:{
    ...mapActions('shop',['calculateTotalWithDiscounts']),
    ...mapMutations("shop", ["aplicateDescountResetUuid", "aplicateDescountReset", "deleteBenefitMember", "deleteBenefit", 'setDescountMember', "setDescountMemberDirect"]),
    async deleteDescount(item){
      this.loadvalid=true
      this.aplicateDescountResetUuid(item.uuid)
      this.deleteBenefit(item.uuid)
      this.setDescountMemberDirect(item.uuid)
      await this.calculateTotalWithDiscounts(this.product)
      this.paymentFormat.total = this.totalBalance
      // showAlertMessage("Notificación","BellIcon","📧 Se ha retirado el beneficio.","success",6000,"bottom-right")
      this.loadvalid=false
    },
    async deleteDescountMember(item){
      this.loadvalid=true
      if( item.modelType === "rates" ){
          const prodInCart = this.cart.items.filter( experiencies => experiencies.keyRate === item.keyRate )
          prodInCart.forEach(experience => {
            this.aplicateDescountResetUuid(experience.uuid)
            this.deleteBenefitMember(experience.uuid)
          })
      } else {
        this.aplicateDescountResetUuid(item.uuid)
        this.deleteBenefitMember(item.uuid)
      }
      await this.calculateTotalWithDiscounts(this.product)
      this.paymentFormat.total = this.totalBalance
      // showAlertMessage("Notificación","BellIcon","📧 Se ha retirado el descuento de membresía.","success",6000,"bottom-right")
      this.loadvalid=false
    },
    async reloadDiscounts(){
      this.$emit('reset-Benefit')
      this.setDescountMember()
      this.$emit('use-benefits')
      await this.calculateTotalWithDiscounts(this.product)
    }
  },
}
</script>

<style scoped>
.small-text{
  font-size: xx-small !important;
}
.margin-customer-card{
  margin: 0.17rem 0;
}
.custom-height{
  height: 3rem;
}
.height{
  height: fit-content;
}
.custom-size {
font-size: 12px !important;
}
</style>